<template>
    <div class="body_bgi full border_box">
      <big-screen-header :headerName="storeName + '疫苗统计'" @selectJob="selectJob" />
  
      <div class="content_box w_full h_full-50 flex_row_around p_10 border_box">
        <!-- 1 -->
        <div class="w_32_p h_full flex_column_between">
          <div class="w_full h_49_p overflow_y_auto p_10 border_box bgs_full chart_box_bgi_1 scroll_box">
            <div class="text_1 jian_bian_text_1 fw_bold pb_10">防疫员</div>
  
            <div class="w_full h_50_p">
              <div class="w_30_p h_full m_0_auto">
                <img 
                  v-if="fangYiYaunDetailData.avatar"
                  class=" border_radius_10 cursor response_img" 
                  :src='filePath + `${fangYiYaunDetailData.avatar}`' alt=""
                >
  
                <img 
                  class="border_radius_10 cursor response_img" 
                  v-else
                  :src='def_user_img' alt=""
                >
              </div>
            </div>
  
            <div class="w_full h_40_p color_fff pv_20 border_box">
              <div class="flex_row_between pr_20">
                <div class="h_24 lh_24 mb_3">姓名： <span class="main_color">{{ fangYiYaunDetailData.name }}</span> </div>
                <div class="h_24 lh_24 mb_3">民族： <span class="main_color">{{ fangYiYaunDetailData.nationName }}</span></div>
                <!-- <div class="h_24 lh_24 mb_3">性别： <span class="main_color">男</span></div> -->
              </div>
  
              <div class="w_full pr_20">
                <!-- <div class="w_full h_24 lh_24 mb_3 ellipsis_text_2">
                  所在嘎查： {{ fangYiYaunDetailData.townName + fangYiYaunDetailData.villageName }}
                </div> -->
              </div>
              
              <div class="flex_row_between pr_20">
                <div class="h_24 lh_24 mb_3">
                  电话：<span class="main_color fw_bold font_number ls_2">{{ formatPhone(fangYiYaunDetailData.phone) }}</span>
                </div>
              </div>

              <div class="flex_row_between pv_20">
                <div class="h_24 lh_24 mb_3 cursor jian_bian_text_1 fw_bold" @click="goToFangYiYuanPage(userId)">
                  查看工作
                </div>
              </div>
            </div>
          </div>
  
          <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
            <yi-miao-chart
              title="疫苗库存统计"
              path="/storePage" 
              :chartData="chartData"
              @clickYiMiao="handleClickVaccinum"
            />
          </div>
        </div> 
  
        <!-- 2 -->
        <div class="w_32_p h_full flex_column_between">
          <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
            <div class="text_1 fw_bold jian_bian_text_1">疫苗信息</div>
  
            <div class="w_full h_full-40 flex_column_between mt_10">
              <!-- 疫苗图片 疫苗信息 -->
              <div class="w_full h_full">
                <div class="w_full h_70_p m_0_auto">
                  <img 
                    v-if="currentVaccinumInfo.picture"
                    class="h_full border_radius_10 cursor response_img" 
                    :src='filePath + `${currentVaccinumInfo.picture}`' alt=""
                  >
  
                  <img 
                    class="border_radius_10 cursor response_img" 
                    v-else
                    :src='def_yi_miao_img' alt=""
                  >
                </div>
  
                <div class="w_full h_30_p ph_10 main_color">
                  <div class="w_full p_5">
                    疫苗名称: 
                    <span class="theme_color_green cursor">
                      {{ currentVaccinumInfo.name }}
                    </span>
                  </div> 
  
                  <div class="w_full h_70 scroll_box overflow_y_auto p_5">
                    说明:
                    <span class="color_fff"> {{ currentVaccinumInfo.description }}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>

           <!-- 疫苗入库记录 -->
          <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
            <div class="full cursor text_1 color_fff flex_column_between">
                <div class="text_1 fw_bold jian_bian_text_1">疫苗库存明细</div>
  
                <div class="w_full h_full-40 overflow_y_auto ph_10 scroll_box">
                  <div 
                    class="cursor text_1 color_fff flex_row_between"
                    v-for="(item, index) in vaccinumInboundList" :key="index"
                  >
                    <div class="w_30_p text_left p_5">
                      <span class="main_color font_number"> {{ formatDate(item.createTime) }}</span>
                    </div> 
                    <div class="w_40_p text_left p_5">
                      <span v-if="item.changeType == 1">领取：</span> 
                      <span v-if="item.changeType == -2">退还：</span> 
                      <span v-if="item.changeType == 3">防疫记录作废：</span> 
                      <span v-if="item.changeType == -3">防疫：</span> 
                      <span v-if="item.changeType == 4">用量修正+：</span> 
                      <span v-if="item.changeType == -4">用量修正-：</span> 
                      <span class="main_color font_number"> {{ item.changeNum }}</span>
                    </div> 
                    <div class="w_30_p text_left p_5">
                      库存：
                      <span class="main_color font_number"> {{ item.amount }}</span>
                    </div> 
                  </div>
                </div>
              </div>
          </div>
        </div> 
  
        <!-- 3 -->
        <div class="w_32_5_p h_full list_box_bgi_1 bgs_full">
          <div class="full p_10">
            <div class="w_full text_left">
              <div class="w_100 jian_bian_text_1 text_1 fw_bold">免疫记录</div>
            </div>

            <div class="w_full p_10 flex_row_start">           
              <div class="w_30_p">
                <el-select 
                  class="w_full_i" 
                  v-model="muHuId" size="mini" clearable 
                  placeholder="牧户" filterable
                  @change="selectMuHu"
                  @clear="getRecordsYiListByPersonId(userId, jobId)"
                >
                  <el-option
                    v-for="item in userNameList"
                    :key="item.userId"
                    :label="item.userName"
                    :value="item.userId">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="w_full h_full-60 flex_column_between">
              <div class="scroll_box w_full h_full-10 overflow_y_auto">
                <dv-loading v-if="!filterUserList.length" class="color_fff">数据加载中...</dv-loading>
                <div 
                  class="w_full flex_row_between flex_wrap text_1 pv_10 ph_10 color_fff border_b_1_s_main_color hover_main_bgc cursor" 
                  v-for="(item, index) in filterUserList" :key="index"
                >
                  <div class="w_50_p p_2">
                    <span class="">免疫日期：</span>
                    <span class="main_color font_number">{{ formatDate(item.dateTime) }}</span> 
                  </div>
                  <div class="w_50_p p_2 cursor">
                    <span class="">牧户：</span>
                    <span class="main_color">{{ item.userName }}</span>
                  </div>
                  <div class="w_50_p p_2">
                    <span class="">免疫疫苗：</span>
                    <span class="theme_color_green">{{ item.vaccinumName }}</span>
                  </div>
                  <div class="w_50_p p_2">
                    <span class="">免疫动物类型：</span>
                    <span class="main_color">{{ item.animalName }}</span>
                  </div>
                  <div class="w_50_p p_2">
                    <span class="">动物存栏量：</span>
                    <span class="main_color font_number">{{ item.animalCount }}</span>
                  </div>
                  <div class="w_50_p p_2">
                    <span class="">免疫动物数量：</span>
                    <span class="main_color font_number">{{ item.fangYiCount }}</span>
                  </div>
                  <div class="w_50_p p_2">
                    <span class="">疫苗用量：</span>
                    <span class="main_color">{{ item.vaccinumAmount }}</span>
                  </div>
                  <div class="w_full p_2">
                    <span class="">免疫病种：</span>
                    <span class="main_color">{{ item.remark }}</span>
                  </div>
                </div>
              </div> 

              <!-- 分页 -->
              <!-- <div class="w_full h_35">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handlePageChange"
                  :page-sizes="[50, 100, 200, 300]"
                  layout="total, sizes, prev, pager, next"
                  :total="total">
                </el-pagination>
              </div> -->
            </div>
          </div>
        </div> 
      </div>
    </div>
  </template>
  
  <script>
    import defMixin from '@/mixins/def.js' 
    import componentsMixin from '@/mixins/components.js'
    import imgMixin from '@/mixins/def-img.js'
    import dataChartMixin from '@/mixins/data-charts.js'
  
    import { getAntiepidemicPersonInfo, getRecordsYiListByPersonId } from '@/api/fang-yi/fang-yi-yuan.js'
    import { getInventory, getInventoryChangeLog } from '@/api/fang-yi/yi-miao.js'
    import { getVaccinumTree } from '@/api/fang-yi/fang-yi.js'
  
    import { filePath } from "@/const"
  
    export default {
      name: 'ErpPerson',
      mixins: [defMixin, componentsMixin, imgMixin, dataChartMixin],
      data() {
        return {
          storeId: null,
          storeName: null,

          jobId: null,
          userId: 1087,

          fangYiYaunDetailData: {},
  
          filePath: filePath,

          muHuId: null,
          
          chartData: {
            unit: '毫升、头份',
            isShowUnit: true,
            data: []
          },

          storeId: null,
  
          vaccinumList: [],
  
          // 动防站点 【负责人】 信息
          personData: {
            name: '',
            minzu: '',
            gender: '',
            address: '',
            townName: '',
            phone: ''
          },
  
          currentVaccinumInfo: {},
          vaccinumInboundList: [],

          fangYiList: [],

          userList: [],
          filterParams: {
            jobId: '',
            userId: '',
          },
          filterUserList: [],
          userNameList: []
        }
      },
  
      async mounted() {
        let storeId = this.$route.params.storeId
        let storeName = this.$route.params.storeName
        let userId = this.$route.params.userId

        this.storeId = storeId
        this.storeName = storeName
        this.userId = userId

        // 获取 防疫员 详情
        this.getAntiepidemicPersonInfo(userId)
      },
  
      methods: {
        // 获取 防疫员详情
        async getAntiepidemicPersonInfo(userId) {
          let res = await getAntiepidemicPersonInfo(userId)
          try {
            if (res.code == 1000) {
              this.fangYiYaunDetailData = res.data
            }
          } catch(err) {
            console.log(err)
          }
        },

        // 获取 疫苗 库存 信息
        async getInventoryList(storeId, jobId) {
          let params = {
            storeId,
            jobId,
            page: 1,
            size: 1000
          }
          let res = await getInventory(params)
          try {
            if (res.code == 1000 && res.data.list.length) {
              this.chartData = {
                unit: '毫升、头份',
                isShowUnit: true,
                data: this.formatYiMiaoList(res.data.list)
              }
  
              // 显示 默认 疫苗详情
              let defaultVaccinum = {id: res.data.list[0].goodsId, name: res.data.list[0].goodsName}
              this.handleClickVaccinum(defaultVaccinum)
            } else {
              this.chartData = {}
              this.currentVaccinumInfo = {}
              this.vaccinumInboundList = []
              this.vaccinumOutboundList = []
            }
          } catch (err) {
            console.log(err)
          }
        },

        // 获取疫苗入库信息
        async getInventoryInBountList(storeId, goodsId) {
          this.vaccinumInboundList = []
          // const CHANGETYPE_INITIAL: number = 0; // 库存登记-入库 (中心 入库)
          // const CHANGETYPE_RECIEVE_FROM: number = 1; // 领取上级给的-入库 （动防站 、防疫员）
          // const CHANGETYPE_RETURN_FROM: number = 2;  // 接收下级退还-入库 （中心、动防站）

          // const CHANGETYPE_DISTRIBUTE_TO: number = -1;   // 发放给下级-出库 （中心、动防站）
          // const CHANGETYPE_RETURN_TO: number = -2;   // 退还给上级-出库 （动防站 、防疫员）

          let params = {
            storeId,
            goodsId,
            // changeType: 1,
            page: 1,
            size: 1000
          }
          let res = await getInventoryChangeLog(params);
          try {
            if (res.code == 1000) {
              this.vaccinumInboundList = res.data.list
            }
          }
          catch (err) {
            console.log(err)
          }
        },

        // 获取 防疫员的 防疫【牧户】列表
        async getRecordsYiListByPersonId(personId, jobId, animalId, vaccinumId) {
          let res = await getRecordsYiListByPersonId(personId, jobId, animalId, vaccinumId)
          // debugger
          try {
            if (res.code == 1000) {
              this.userList = res.data
              this.filterUserList = res.data

              let userNameList = []
              res.data.map(item => {
                userNameList.push({ userId: item.userId, userName: item.userName })
                this.userNameList = Array.from(new Set(userNameList.map(JSON.stringify)), JSON.parse);
              })
            }
          } catch(err) {
            console.log(err)
          }
        },
  
        // 监听子组件 传递 的信息
        handleClickVaccinum(val) {
          // 获取疫苗详情
          this.getVaccinumDetail(val.name)
  
          // 获取 防疫员的疫苗入库记录
          this.getInventoryInBountList(this.storeId, val.id)

          // 获取 防疫员的 这个疫苗的 防疫记录 （疫苗使用记录）
          this.getRecordsYiListByPersonId(this.userId, this.jobId, null, val.id)
        },
  
        // 获取 疫苗详情
        async getVaccinumDetail(name) {
          let res = await getVaccinumTree(name);
          try {
            if (res.code == 1000 && res.data[0].children.length && res.data[0].children[0].name) {
              this.currentVaccinumInfo = res.data[0].children[0]
            } else {
              this.currentVaccinumInfo = {
                picture: '',
                name: '',
                description: ''
              }
            }
          }
          catch (err) {
            console.log(err)
          }
        },
  
        // 格式化 图表所用的 数据
        formatYiMiaoList(list) {
          let newList = []
          let item = {}
  
          for (let i in list) {
            item = { 
              id: list[i].goodsId,
              name: list[i].goodsName,
              value: list[i].quantity,
              unit: '毫升、头份',
            }
            newList.push(item)
          }
  
          return newList
        },
  
        // 监听 选择 防疫工作
        selectJob(jobId) {
          this.jobId = jobId
          this.filterParams.jobId = jobId

          // 获取 防疫员的 疫苗库存信息
          this.getInventoryList(this.storeId, jobId)
        },

        // 选择 牧户
        selectMuHu(val) {
          this.filterParams.userId = val
          this.filterUserList = []

          this.filterUserList = this.filterParams.animalId  ? 
            this.userList.filter(item => item.animalId == this.filterParams.animalId && item.userId == val) 
            : 
            this.userList.filter(item => item.userId == val)
        }
      }
    }
  </script>